/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


//window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//const files = require.context('./', true, /\.vue$/i);
//files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
/*
const app = new Vue({
    el: '#app',
});
*/

require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl-BE.min');
require('select2');
require('select2/dist/js/i18n/nl');
//require('datatables.net');
//require('datatables.net-bs4');
//require('datatables.net-rowgroup');
//require('datatables.net-rowgroup-bs4');
import Quill from 'quill'
import Quagga from 'quagga';


let mailContents;
if ($('#quill-editor').length) {
    Quill.register(Quill.import('attributors/style/align'), true);
    Quill.register(Quill.import('attributors/style/background'), true);
    Quill.register(Quill.import('attributors/style/color'), true);
    Quill.register(Quill.import('attributors/style/direction'), true);
    Quill.register(Quill.import('attributors/style/font'), true);
    const FontStyle = Quill.import('attributors/style/font');

    FontStyle.whitelist = ['sans-serif', 'monospace', 'serif', 'Georgia', 'palatino-linotype', 'times-new-roman', 'Arial', 'Helvetica', 'arial-black',
        'comic-sans-ms', 'Charcoal', 'Impact', 'lucida-sans-unicode', 'lucida-grande', 'Tahoma', 'Geneva', 'trebuchet-ms', 'Verdana',
        'courier-new', 'lucida-console', 'Mirza', 'Roboto'];

    Quill.register(FontStyle, true);

    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
    mailContents = new Quill('#quill-editor', {
        modules: {toolbar: '#quill-toolbar'},
        theme: 'snow'
    });
}

// DATEPICKER & SELECT SETUP

$.fn.datepicker.defaults.format = 'dd/mm/yyyy';
$.fn.datepicker.defaults.autoclose = true;
$.fn.datepicker.defaults.assumeNearbyYear = true;
$.fn.datepicker.defaults.calendarWeeks = true;
$.fn.datepicker.defaults.language = 'nl-BE';
$.fn.datepicker.defaults.todayBtn = 'linked';
$.fn.datepicker.defaults.todayHighlight = true;
$.fn.datepicker.defaults.weekStart = 1;

$.fn.select2.defaults.theme = 'bootstrap4';
$.fn.select2.defaults.language = 'nl';
$.fn.select2.defaults.set('templateSelection', item => {
    if (typeof item.element !== 'undefined' && item.element.parentElement.tagName === "OPTGROUP") {
        return item.element.parentElement.label + ' -> ' + item.text;
    }
    return item.text;
});

window.addEventListener('load', function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        let forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('click', function () {
                form.classList.add('was-validated');
            });
            form.addEventListener('change', function () {
                form.classList.add('was-validated');
            });
            form.addEventListener('keydown', function () {
                form.classList.add('was-validated');
            });
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);

$('[data-toggle="tooltip"]').tooltip();

$('select:not(.nosel2)').select2().on('select2:closing', function (e) {
    $(e.target).data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation();
    });
});

// on first focus (bubbles up to document), open the menu
$(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
    $(this).closest(".select2-container").siblings('select:enabled').select2('open');
});

$('.modal').on('shown.bs.modal', function () {
    $(this).find('[autofocus]').focus();
});

const form = $('#create-transaction-form');
const addButton = $('#add-element-button');

form.submit(function (e) {
    if ($('#create-transaction-form').find('input[type=hidden]').length < 5) {
        e.preventDefault();
        e.stopPropagation();
    }
});

$('#element-form input').keypress(function (event) {
    if (event.keyCode === 13) {
        addButton.click();
    }
});

$('#create-transaction-form #counterparty').change(function () {
    if (!isNaN(parseInt($(this).val())) && parseInt($("#counterparty option:selected").data('member')) === 1) {
        $('#settlement').val(`I-${$(this).val()}`).trigger('change');
    }
});

function splitBalance(total, count) {
    const amount = (total / count).toFixed(2);
    let output = Array(count).fill(amount);
    const diff = total - (parseFloat(amount) * count);
    for (let i = 0; i < Math.abs(Math.round(100 * diff)); i++) {
        output[i] = (parseFloat(output[i]) + 0.01 * Math.sign(diff)).toFixed(2);
    }
    return output
}

addButton.click(function () {
    const elementForm = $('#element-form');
    const elem = $('#size option:selected');
    const subcategory = $('#subcategory');
    const balance = $('#balance');
    const description = $('#description');
    elementForm.addClass('was-validated');

    const nr = parseInt($('#element-id').val());
    let bal = parseFloat(balance.val());
    let subcat_id = subcategory.val();
    let subcat = $('#subcategory option:selected').text();
    let count = $('#count').val();
    let size = $('#size').val();
    let itemString = '';
    if (elem.parent().attr('label'))
        itemString = `${elem.parent().attr('label')} (${elem.text()})`;

    if (window.stock) {
        bal = parseFloat(elem.data('price')) * parseFloat(count);
        subcat_id = elem.data('subid');
        subcat = elem.data('subname');
        balance.val(bal);
        subcategory.val(subcat_id);
        description.val(`Verkoop  ${itemString}`);
        count = -count;
    }

    if (elementForm[0].checkValidity() === false)
        return;

    if (window.buy || window.multi)
        bal = -bal;
    bal = bal.toFixed(2);

    if (window.multi) {
        const cps = $('#invoice').val();
        const balances = splitBalance(bal, cps.length);
        cps.forEach((cp, idx) => createOrUpdateElement(nr,
            description.val(),
            subcat_id,
            subcat,
            balances[idx],
            cp,
            $('#invoice option:selected').eq(idx).text(),
            size,
            itemString,
            count));
    } else {
        createOrUpdateElement(nr,
            description.val(),
            subcat_id,
            subcat,
            bal,
            $('#invoice').val(),
            $('#invoice option:selected').text(),
            size,
            itemString,
            count);
    }

    $('#new-element').modal('hide');
    updateTotal();
});

$(document).on('click', '#edit-element-button', function () {
    cleanForm();
    const id = $(this).data('id');
    $('#element-id').val(id);
    const data = $(`input.e-${id}`);
    $('#description').val(data.eq(0).val());
    $('#subcategory').val(data.eq(1).val()).trigger('change');
    $('#balance').val(data.eq(2).val());
    $('#invoice').val(data.eq(3).val()).trigger('change');
    $('#size').val(data.eq(4).val()).trigger('change');
    $('#count').val(data.eq(5).val());

    $('#new-element-title').text('Element wijzigen');
    addButton.text('Wijzigingen opslaan');

    $('#new-element').modal();
});

$(document).on('click', '#delete-element-button', function () {
    const id = $(this).data('id');
    $(`.e-${id}`).remove();
    updateTotal();
});

$('#create-complex-button').click(function () {
    cleanForm();
    $('#new-element-title').text('Nieuw complex element');
});

$('#create-simplebuy-button').click(function () {
    cleanForm();
    $('#new-element-title').text('Nieuwe eenvoudige aankoop');
    $('#balance').attr('min', '0');
    $('#invoice-group').hide();
    $('#size-group').hide();
    $('#count-group').hide();
    window.buy = true;
});

$('#create-simplesell-button').click(function () {
    cleanForm();
    $('#new-element-title').text('Nieuwe eenvoudige verkoop');
    $('#balance').attr('min', '0');
    $('#invoice-group').hide();
    $('#size-group').hide();
    $('#count-group').hide();
});

$('#create-private-button').click(function () {
    cleanForm();
    $('#new-element-title').text('Nieuwe privé aankoop');
    $('#balance').attr('min', '0');
    $('#size-group').hide();
    $('#count-group').hide();
    $('#empty-invoice-option').remove();
    $('#invoice').removeAttr('data-allow-clear').select2({
        allowClear: false
    });
    window.buy = true;
});

$('#create-stock-button').click(function () {
    cleanForm();
    $('#new-element-title').text('Nieuwe verkoop van stockartikel');
    $('#description-group').hide();
    $('#subcategory-group').hide();
    $('#balance-group').hide();
    $('#invoice-group').hide();
    $('#empty-size-option').remove();
    $('#size').attr('min', '0').removeAttr('data-allow-clear').select2({
        allowClear: false
    });
    window.stock = true;
});

$('#create-split-button').click(function () {
    cleanForm();
    $('#new-element-title').text('Nieuwe gesplitste privé-aankoop');
    $('#balance').attr('min', '0');
    $('#size-group').hide();
    $('#count-group').hide();
    $('#empty-invoice-option').remove();
    $('#invoice').removeAttr('data-allow-clear').attr('multiple', 'multiple').attr('required', '').select2({
        allowClear: false
    });
    window.multi = true;
});

$('#create-enrollment-button').click(function () {
    const subField = $('#subcategory');
    $('#add-enrollment-button').text('Toevoegen');
    $('#element-id').val('');
    $('#description').val('');
    $('#balance').val('0');
    subField.val($('option', subField).eq(0).val()).trigger('change');
    $('#new-element-title').text('Nieuw element');
});

$(document).on('click', '#edit-enrollment-button', function () {
    const id = $(this).data('id');
    $('#element-id').val(id);
    const data = $(`input.e-${id}`);
    $('#description').val(data.eq(0).val());
    $('#subcategory').val(data.eq(1).val()).trigger('change');
    $('#balance').val(data.eq(2).val());

    $('#new-element-title').text('Element wijzigen');
    $('#add-enrollment-button').text('Wijzigingen opslaan');

    $('#new-element').modal();
});

$("#add-enrollment-button").click(function () {
    const elementForm = $('#element-form');
    elementForm.addClass('was-validated');
    if (elementForm[0].checkValidity() === false)
        return;

    let id = parseInt($('#element-id').val());
    const description = $('#description').val();
    const balance = parseFloat($('#balance').val()).toFixed(2);

    if (isNaN(id)) {
        window.nr += 1;
        id = window.nr;
    } else {
        $(`.e-${id}`).remove();
    }

    $('<input>').attr({
        type: 'hidden', name: 'description[]', value: description, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'subcategory[]', value: $('#subcategory').val(), class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'balance[]', value: balance, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'id[]', value: id, class: `e-${id}`,
    }).appendTo(form);
    $('#elements > tbody:last-child')
        .append(`
                    <tr class='e-${id}'>
                        <td>${description}</td>
                        <td>${$('#subcategory option:selected').text()}</td>
                        <td>€ ${balance}</td>
                        <td>
                            <button type="button" class="btn btn-primary mb-1" id="edit-enrollment-button" data-id=${id}>
                                <span class="oi oi-pencil"></span>
                            </button>
                            <button type="button" class="btn btn-danger mb-1" id="delete-element-button" data-id=${id}>
                                <span class="oi oi-trash"></span>
                            </button>
                        </td>
                    </tr>`);

    $('#new-element').modal('hide');
    updateTotal();
});

function updateTotal() {
    let total = 0;
    $('input[name*=balance]').each(function () {
        total += parseFloat($(this).val());
    });
    $('#float-right').text(`€ ${total.toFixed(2)}`);
}

function createOrUpdateElement(id, description, subcategory_id, subcategory, balance, counterparty_id, counterparty, size_id, size, count) {

    if (isNaN(id)) {
        window.nr += 1;
        id = window.nr;
    } else {
        $(`.e-${id}`).remove();
    }

    $('<input>').attr({
        type: 'hidden', name: 'description[]', value: description, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'subcategory[]', value: subcategory_id, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'balance[]', value: balance, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'invoice[]', value: counterparty_id, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'size[]', value: size_id, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'count[]', value: count, class: `e-${id}`,
    }).appendTo(form);
    $('<input>').attr({
        type: 'hidden', name: 'id[]', value: id, class: `e-${id}`,
    }).appendTo(form);
    $('#elements > tbody:last-child')
        .append(`
                    <tr class='e-${id}'>
                        <td>${description}</td>
                        <td>${subcategory}</td>
                        <td>${counterparty}</td>
                        <td>${size}</td>
                        <td>${count}</td>
                        <td>€ ${balance}</td>
                        <td>
                            <button type="button" class="btn btn-primary mb-1" id="edit-element-button" data-id=${id}>
                                <span class="oi oi-pencil"></span>
                            </button>
                            <button type="button" class="btn btn-danger mb-1" id="delete-element-button" data-id=${id}>
                                <span class="oi oi-trash"></span>
                            </button>
                        </td>
                    </tr>`);
}

function cleanForm() {
    const subField = $('#subcategory');
    const sizeField = $('#size');
    const invoiceField = $('#invoice');
    if ($('#empty-size-option').length === 0) {
        $('<option>').attr({id: 'empty-size-option'}).appendTo(sizeField);
    }
    sizeField.attr('data-allow-clear', 'true').select2({
        allowClear: true
    });
    if ($('#empty-invoice-option').length === 0) {
        $('<option>').attr({id: 'empty-invoice-option'}).appendTo(invoiceField);
    }
    invoiceField.attr('data-allow-clear', 'true').removeAttr('multiple').removeAttr('required').select2({
        allowClear: true
    });
    addButton.text('Toevoegen');
    $('#element-id').val('');
    $('#description').val('');
    subField.val($('option', subField).eq(0).val()).trigger('change');
    $('#balance').val('0').removeAttr('min');
    invoiceField.val('').trigger('change');
    sizeField.val('').trigger('change');
    $('#count').val('0').removeAttr('min');
    window.buy = false;
    window.stock = false;
    window.multi = false;
    $('#description-group').show();
    $('#subcategory-group').show();
    $('#balance-group').show();
    $('#invoice-group').show();
    $('#size-group').show();
    $('#count-group').show();
}

$('.edit-address-button').click(function () {
    $('#edit-address-form').attr('action', `/addresses/${$(this).data('id')}`);
    const elements = $(this).parent().parent().children();
    $('#edit-street').val(elements.eq(0).text());
    $('#edit-number').val(elements.eq(1).text());
    $('#edit-zip').val(elements.eq(2).text());
    $('#edit-city').val(elements.eq(3).text());
});

$('.edit-comment-button').click(function () {
    $('#edit-comment-form').attr('action', `/comments/${$(this).data('id')}`);
    $('#delete-comment-form').attr('action', `/comments/${$(this).data('id')}`);
    const elements = $(this).parent().children();
    $('#edit-date').val(elements.eq(1).text());
    $('#edit-contents').val(elements.eq(2).text());
});
/*
const table = $('#transactions-table').DataTable({
    "ajax": "/test",
    "processing": true,
    "columns": [
        {
            "className": 'details-control',
            "orderable": false,
            "defaultContent": '<span class="oi oi-chevron-right"></span>'
        },
        {"data": "date"},
        {"data": "counter_party"},
        {"data": "settlement"},
        {"data": "balance"},
    ],
    "order": [[1, 'asc']],
    "language": {
        "url": "/js/dataTables.dutch.lang"
    }
});
*/
/*
.on('init.dt', function (e, settings) {
var api = new $.fn.dataTable.Api(settings);
api.rows().every(function () {
    var tr = $(this.node());
    this.child(childRows(this.data())).show();
    tr.addClass('shown');
    tr.children().first().html('');
});
});
*/
/*
function childRows(data) {
    let result = '<table style="width:100%"><thead><tr style="cursor: default"><th>Beschrijving</th><th>Kostenpost</th><th>Balans</th></tr></thead><tbody>';
    data.elements.forEach(element => {
        result += `<tr style="cursor: default;"><td>${element.description}</td><td>${element.subcategory}</td><td>€ ${element.balance}</td></tr>`;
    });
    return result + '</tbody></table>'
}
 */
/*
$('#transactions-table tbody').on('click', 'td.details-control', function (e) {
    e.stopPropagation();
    var tr = $(this).closest('tr');
    var row = table.row(tr);

    if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
        tr.children().first().html('<span class="oi oi-chevron-right"></span>');
    } else {
        // Open this row
        row.child(childRows(row.data())).show();
        tr.addClass('shown');
        tr.children().first().html('<span class="oi oi-chevron-bottom"></span>');
    }
}).on('click', 'tr', function () {
    window.location = '/transactions/' + table.row(this).data().id;
});

$('.datatable').DataTable({
    "language": {
        "url": "/js/dataTables.dutch.lang"
    }
});
*/
$('.select-all').click((e) => {
    const boxes = $('.G-' + e.target.id);
    let newState = true;
    if (boxes.first().prop('checked')) {
        newState = false;
    }
    boxes.prop('checked', newState);
});

$('.cancel-activity').click((e) => {
    e.stopPropagation();
    window.axios.delete('/enrollments/' + e.delegateTarget.id + '/single')
        .then(() => window.location.reload())
        .catch((err) => alert('FOUT'));
});

$('#send-test-mail').click((e) => {
    e.preventDefault();
    const form = $('#mail-form')[0];
    if (form.checkValidity()) {
        const formData = new FormData(form);
        formData.append('contents', mailContents.root.innerHTML);
        window.axios.post('/mail/test', formData)
            .then((res) => alert('Test email verstuurd!'))
            .catch((err) => alert('ERROR: ' + err));
    }
});

$("#mail-form").on("submit", function () {
    var hvalue = $('.ql-editor').html();
    $(this).append("<textarea name='contents' style='display:none'>" + hvalue + "</textarea>");
    return confirm('Ben je zeker dat je de mail wilt versturen?\n\nDubbelcheck zeker nog eens alle velden en gebruik de testfunctie voordat je verder gaat...\n\nDruk op OK om te versturen, druk op annuleren om alles nog eens na te kijken.');
});

$('#cm-activity').change(function () {
    const option = $(this).find("option:selected");
    $('#cm-start').val(option.data('start'));
    $('#cm-days').val(option.data('days'));
});

$('#enroll-activity').change(function () {
    const option = $(this).find("option:selected");
    $('#enroll-balance').val(option.data('price'));
    $('#enroll-subcategory').val(option.data('subcategory')).trigger('change');
});

$('.confirmButton').click((e) => {
    e.stopPropagation();
    window.axios.post('/statements/' + e.delegateTarget.dataset.id + '/confirm')
        .then((res) => {
            if (e.delegateTarget.classList.contains('btn-danger')) {
                e.delegateTarget.classList.remove('btn-danger');
                e.delegateTarget.classList.add('btn-success');
                e.delegateTarget.innerHTML = '<span class="oi oi-check"></span>';
                e.delegateTarget.parentElement.parentElement.style.backgroundColor = 'inherit';
            } else {
                e.delegateTarget.classList.remove('btn-success');
                e.delegateTarget.classList.add('btn-danger');
                e.delegateTarget.innerHTML = '<span class="oi oi-x"></span>';
                e.delegateTarget.parentElement.parentElement.style.backgroundColor = '#71c781';
            }
        }).catch((err) => alert('ERROR: ' + err));
});

$('.show-matches-button').click((e) => {
    const data = e.delegateTarget.dataset;
    fetch('/imported_statements/' + data.id + '/matches')
        .then(handleErrors)
        .then((result) => result.json())
        .then((data) => {
                const table = $('#matched-transactions-table');
                table.html('');
                data.forEach((transaction) =>
                    table.append(`
        <tr>
            <td>W${transaction.id}</td>
            <td>${transaction.counterParty}</td>
            <td>${transaction.source}</td>
            <td>€ ${transaction.balance}</td>
        </tr>`))
            }
        )
        .catch((err) => alert('FOUT!\n\n' + err));

    $('#modal-account').html('').html(data.account);
    $('#modal-card').html('').html(data.card);
    $('#modal-date').html('').html(data.date);
    $('#modal-cp-account').html('').html(data.cnr);
    $('#modal-cp-name').html('').html(data.cname);
    $('#modal-note').html('').html(data.note);
    $('#modal-balance').html('').html('€ ' + data.balance);
    $('#modal-id').val(data.id);

    $('#show-matches-modal').modal();
});

$('#accept-match').click(e => {
    const id = $('#modal-id').val();
    deleteStatement(id).then(res => {
            $('#show-matches-modal').modal('hide');
            $('#statement-row-' + id).remove();
        })
        .catch(err => alert('FOUT\n\n' + err));
});

$('#ignore-match').click(e => {
    const id = $('#modal-id').val();
    fetch('/imported_statements/' + id + '/ignore',
        {method: 'POST', headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}})
        .then(handleErrors).then(() => location.reload())
        .catch(err => alert('FOUT\n\n' + err));
});

$('.show-transfer-button').click((e) => {
    const data = e.delegateTarget.dataset;
    $('#transfer-origin').val(data.from).trigger('change');
    $('#transfer-destination').val(data.to).trigger('change');
    $('#transfer-date').val(data.date);
    $('#transfer-balance').val(data.balance);
    $('#modal-id').val(data.id);

    $('#show-transfer-modal').modal();
});

$('#transfer-form').submit(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();
    for (let i = 0; i < this.length; ++i) {
        formData.append(this[i].name, this[i].value);
    }
    fetch('/account_transfers', {
        method: 'POST',
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), 'Accept': 'application/json'},
        body: formData
    }).then(handleErrors).then(() => {
        const id = $('#modal-id').val();
        deleteStatement(id).then(res => {
            $('#show-transfer-modal').modal('hide');
            $('#statement-row-' + id).remove();
        }).catch(err => alert('FOUT\n\n' + err));
    }).catch(err => alert('FOUT\n\n' + err));
});

$('.show-settle-button').click(e => {
    const data = e.delegateTarget.dataset;
    fetch('/invoices/' + data.invoice + '/settle', {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({date: data.date, card: data.card, overage: 0})
    }).then(handleErrors).then(() => {
        const id = data.id;
        deleteStatement(id).then(res => {
            $('#statement-row-' + id).remove();
        }).catch(err => alert('FOUT\n\n' + err));
    }).catch(err => alert('FOUT\n\n' + err));
});

$('.show-cp-button').click(e => {
    const data = e.delegateTarget.dataset;
    fetch('/counter_parties/' + data.cp + '/invoices')
        .then(handleErrors)
        .then((result) => result.json())
        .then((data) => {
                const table = $('#matched-invoices-table');
                table.html('');
                data.forEach((invoice) =>
                    table.append(`
        <tr>
            <td>F${invoice.id}</td>
            <td>€ ${invoice.balance}</td>
        </tr>`))
            }
        )
        .catch((err) => alert('FOUT!\n\n' + err));

    $('#cp-modal-account').html('').html(data.account);
    $('#cp-modal-date').html('').html(data.date);
    $('#cp-modal-cp').html('').html(data.name);
    $('#cp-modal-note').html('').html(data.note);
    $('#cp-modal-balance').html('').html('€ ' + data.balance);

    $('#show-cp-modal').modal();
});

$('.show-manual-button').click((e) => {
    const data = e.delegateTarget.dataset;

    window.open('/transactions/create?settlement=' + data.settlement + '&date=' + data.date + '&id=' + data.id , '_blank').focus();
});

function deleteStatement(id) {
    console.log(id);
    return fetch('/imported_statements/' + id,
        {method: 'DELETE', headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}})
        .then(handleErrors)
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

$('.checkInButton').click((e) => {
    e.stopPropagation();
    window.axios.post('/activities/' + e.delegateTarget.dataset.act + '/checkin', {'member': e.delegateTarget.dataset.id})
        .then((res) => {
            if (e.delegateTarget.classList.contains('btn-danger')) {
                e.delegateTarget.classList.remove('btn-danger');
                e.delegateTarget.classList.add('btn-success');
                e.delegateTarget.innerHTML = '<span class="oi oi-check"></span>';
                e.delegateTarget.parentElement.parentElement.style.backgroundColor = 'inherit';
            } else {
                e.delegateTarget.classList.remove('btn-success');
                e.delegateTarget.classList.add('btn-danger');
                e.delegateTarget.innerHTML = '<span class="oi oi-x"></span>';
                e.delegateTarget.parentElement.parentElement.style.backgroundColor = '#71c781';
            }
        }).catch((err) => alert('ERROR: ' + err));
});

$('#scanButton').click(e => {
    if (!window.activity) {
        window.activity = e.delegateTarget.dataset.act;
    }
    $('#scan-modal').modal();
    Quagga.init({
        numOfWorkers: 2,
    }, function (err) {
        if (err) {
            console.log(err);
            alert('Starten van scanner mislukt');
            return
        }
        window.quagga = true;
        Quagga.start();
        console.log("Scanner initialized succesfully. Starting now.");
    });
});

Quagga.onDetected(function (data) {
    Quagga.stop();
    $('#scan-modal').modal('hide');
    window.axios.get('/activities/' + window.activity + '/ssidLookup?id=' + data.codeResult.code)
        .then(function (result) {
            var html = '';
            result.data.forEach(function (item) {
                let color = 'btn-success';
                if (item.pivot.checked_in_at)
                    color = 'btn-danger';
                html += `<button type="button" class="btn checkin-user ${color}" data-id="${item.id}">${item.firstname} ${item.lastname}</button>`;
            });
            $('#scan-results').html(html);
            $('.checkin-user').click(checkin_user);
            $('#results-modal').modal();
        })
        .catch((err) => alert('ERROR SENDING REQUEST: ' + err));
});

function checkin_user(e) {
    window.axios.post('/activities/' + window.activity + '/checkin', {'member': e.delegateTarget.dataset.id})
        .then((res) => {
            const row = document.getElementById('member-' + e.delegateTarget.dataset.id);
            if (res.data.checkout) {
                row.style.backgroundColor = 'inherit';
                row.lastElementChild.lastElementChild.classList.remove('btn-danger');
                row.lastElementChild.lastElementChild.classList.add('btn-success');
                row.lastElementChild.lastElementChild.innerHTML = '<span class="oi oi-check"></span>';
            } else {
                row.style.backgroundColor = '#71c781';
                row.lastElementChild.lastElementChild.classList.remove('btn-success');
                row.lastElementChild.lastElementChild.classList.add('btn-danger');
                row.lastElementChild.lastElementChild.innerHTML = '<span class="oi oi-x"></span>';
            }
            $('#results-modal').modal('hide');
        }).catch((err) => alert('ERROR: ' + err));
}
